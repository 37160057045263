<template>
  <div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">{{ title }}主題</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <button
          class="button text-dark-5 bg-theme-5 shadow-md mr-2 flex items-center"
          @click="back"
        >
          <FontAwesome icon="arrow-left" type="fas" class="w-4 h-4 mr-1" />
          取消
        </button>
        <button
          class="button text-white bg-theme-1 shadow-md mr-2 flex items-center"
          @click="save"
        >
          <FontAwesome icon="check-circle" type="fas" class="w-4 h-4 mr-1" />
          儲存
        </button>
      </div>
    </div>

    <div class="grid grid-cols-12 gap-3">
      <div class="intro-y col-span-12 lg:col-span-9">
        <div class="intro-y box p-5 mt-5">
          <div
            class="flex items-center border-b border-gray-200 dark:border-dark-5 flex-col"
          >
            <h2 class="font-medium text-base mr-auto">列表縮圖</h2>
          </div>
          <div class="w-full pt-2">
            <file-uploader
              id="topic-thumbnail-uri"
              class="h-72 border rounded"
              mode="image"
              defaultImage="http://placehold.jp/470x246.png?text=470x246"
              :limitedHeight="246"
              :limitedWidth="470"
              :canDelete="canEdit"
              :modelValue="form.formData.Picture?.Uri"
              :action="uploadAction"
              :autoUpload="true"
              @update:modelValue="
                (value) => {
                  if (form.formData) {
                    if (form.formData.Picture)
                      form.formData.Picture.Uri = value;
                    else form.formData.Picture = { Uri: value };
                  }
                }
              "
            />
          </div>
        </div>
      </div>
      <div class="intro-y col-span-12 lg:col-span-3">
        <div class="intro-y box p-5 mt-5">
          <div
            class="flex items-center border-b border-gray-200 dark:border-dark-5"
          >
            <h2 class="font-medium text-base mr-auto">顯示設定</h2>
          </div>
          <div class="">
            <div class="flex flex-col p-1">
              <div class="flex flex-col p-1">
                <label>排序值</label>
                <vxe-input
                  v-model="form.formData.Ordinal"
                  type="integer"
                  clearable
                  placeholder="輸入排序值"
                ></vxe-input>
              </div>

              <label>發佈起始日</label>
              <vxe-input
                v-model="form.formData.StartDate"
                type="date"
                clearable
                placeholder=""
              ></vxe-input>
            </div>
            <div class="flex flex-col p-1">
              <label>發佈截止日</label>
              <vxe-input
                v-model="form.formData.EndDate"
                type="date"
                clearable
                placeholder=""
              ></vxe-input>
            </div>

            <div class="flex flex-col p-1 pt-3">
              <label>發佈狀態</label>
              <vxe-switch
                v-model="form.formData.Published"
                open-label="上架"
                close-label="下架"
                :disabled="!canEdit"
              ></vxe-switch>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-12 gap-3">
      <div class="intro-y col-span-12 lg:col-span-12">
        <div class="intro-y box p-5 mt-5">
          <div class="grid grid-flow-row gap-2">
            <div class="grid grid-flow-row gap-1">
              <label>主題名稱</label>
              <vxe-input
                placeholder="主題名稱"
                class="w-full"
                v-model.trim="validate.Title.$model"
                :class="{ 'bg-red-200': validate.Title.$error }"
              ></vxe-input>
              <template v-if="validate.Title.$error">
                <div
                  v-for="(error, index) in validate.Title.$errors"
                  :key="index"
                  class="text-theme-6 mt-2"
                >
                  {{ error.$message }}
                </div>
              </template>
            </div>
            <div class="grid grid-flow-row gap-1"></div>

            <div class="grid grid-flow-row gap-1">
              <label>連結網址</label>
              <vxe-input
                placeholder="連結網址"
                class="w-full"
                v-model="form.formData.Introduction"
              ></vxe-input>
            </div>

            <div class="grid grid-flow-row gap-1">
              <label>主題內容</label>

              <div>
                <div class="wysiwyg-box">
                  <div class="container-fluid container-xl">
                    <div class="row">
                      <div class="col">
                        <CKEditor
                          v-model="form.formData.Content"
                          :editor="classicEditor"
                          :config="simpleEditorConfig"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="w-full sm:w-auto flex pt-4 sm:mt-0">
      <button
        class="button text-dark-5 bg-theme-5 shadow-md mr-2 flex items-center"
        @click="back"
      >
        <FontAwesome icon="arrow-left" type="fas" class="w-4 h-4 mr-1" />
        取消
      </button>
      <button
        class="button text-white bg-theme-1 shadow-md mr-2 flex items-center"
        @click="save"
      >
        <FontAwesome icon="check-circle" type="fas" class="w-4 h-4 mr-1" />
        儲存
      </button>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import CloudFun, {
  defineComponent,
  ref,
  onMounted,
  reactive,
  computed,
} from "@cloudfun/core";
import { useRouter, useRoute, onBeforeRouteLeave } from "vue-router";
import UploadAdapterPlugin from "@/global-components/ckeditor/upload-adapter-plugin";
import FileUploader from "@/cloudfun/components/FileUploader.vue";
import ClassicEditor from "@/global-components/ckeditor/classic-editor";
import "@/global-components/ckeditor/styles.css";
import "@/assets/css/wysiwyg.css";
import { CustomFigureAttributes } from "@/global-components/ckeditor/custom";
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { toRefs, watch, nextTick } from "vue";
import _ from "lodash";

export default defineComponent({
  components: { FileUploader },
  props: {
    id: {
      type: Number,
    },
  },
  setup(props) {
    const router = useRouter();
    const route = useRoute();
    const model = CloudFun.current?.model;
    const canEdit = true;
    const hasChanged = ref(false);
    const original = reactive({ formData: {} });
    const params = reactive({
      insertRows: [],
      updateRows: [],
      deleteRows: [],
    });

    const form = reactive({
      model: "insert",
      formData: {
        Id: 0,
        Title: null,
        Published: false,
        StartDate: null,
        EndDate: null,
        Ordinal: 0,
        Content: "",
        Introduction: null,
        Picture: {
          Id: 0,
          Uri: "",
        },
      },
    });

    const formRules = {
      Title: {
        required,
      },
    };

    const validate = useVuelidate(formRules, toRefs(form.formData));

    const classicEditor = ClassicEditor;
    const simpleEditorConfig = {
      extraPlugins: [UploadAdapterPlugin, CustomFigureAttributes],
      toolbar: {
        shouldNotGroupWhenFull: true,
        items: [
          "sourceEditing",
          "|",
          "heading",
          "|",
          "fontFamily",
          "fontSize",
          "fontColor",
          "fontBackgroundColor",
          "|",
          "bold",
          "italic",
          "underline",
          "bulletedList",
          "numberedList",
          "alignment",
          "outdent",
          "indent",
          "highlight",
          "insertTable",
          "|",
          "link",
          "blockQuote",
          "imageInsert",
          "mediaEmbed",
          // 'codeBlock',
          "htmlEmbed",
          "|",
          "undo",
          "redo",
        ],
      },
      removePlugins: ["Markdown"],
      image: {
        toolbar: [
          "imageStyle:inline",
          "imageStyle:block",
          "imageStyle:side",
          "|",
          "toggleImageCaption",
          "imageTextAlternative",
          "linkImage",
        ],
      },
      table: {
        contentToolbar: [
          "tableColumn",
          "tableRow",
          "mergeTableCells",
          "tableCellProperties",
          "tableProperties",
        ],
      },
      heading: {
        options: [
          {
            model: "paragraph",
            title: "Paragraph",
            class: "ck-heading_paragraph",
          },
          {
            model: "heading1",
            view: { name: "h1", classes: "font-bold custom-big" },
            title: "Heading 1",
            class: "ck-heading_heading1",
          },
          {
            model: "heading2",
            view: { name: "h2", classes: "font-bold custom-default" },
            title: "Heading 2",
            class: "ck-heading_heading2",
          },
        ],
      },
      fontSize: {
        options: [
          {
            title: "特小",
            model: "custom-tiny-tool",
            view: { name: "span", classes: "custom-tiny" },
          },
          {
            title: "小",
            model: "custom-small-tool",
            view: { name: "span", classes: "custom-small" },
          },
          {
            title: "預設",
            model: "custom-default-tool",
            view: { name: "span", classes: "custom-default" },
          },
          {
            title: "大",
            model: "custom-big-tool",
            view: { name: "span", classes: "custom-big" },
          },
          {
            title: "特大",
            model: "custom-huge-tool",
            view: { name: "span", classes: "custom-huge" },
          },
        ],
      },
    };

    onBeforeRouteLeave((to, from) => {
      console.log("hasChanged", _.isEqual(original.formData, form.formData));
      if (hasChanged.value) {
        const answer = window.confirm("尚未儲存，確定離開？");
        return answer;
      }
    });

    watch(form.formData, (first, second) => {
      if (!_.isEqual(original.formData, second)) hasChanged.value = true;
    });

    const title = computed(() => {
      return form.model === "insert" ? "新增" : "編輯";
    });

    onMounted(() => {
      if (route.query && route.query.id) {
        model?.dispatch("topic/find", route.query.id).then(
          (payload) => {
            console.log("payload", payload);
            Object.assign(form.formData, payload);
            form.model = "update";
            original.formData = _.cloneDeep(form.formData);
            nextTick(() => {
              hasChanged.value = false;
            });
          },
          (failure) => {
            console.log("failure", failure);
          }
        );
      }
    });

    // modal 執行 新增or修改
    const save = () => {
      validate.value.$touch();
      console.log(validate.value);
      if (validate.value.$invalid) return;

      if (form.formData.Id === 0) params.insertRows = [form.formData];
      else params.updateRows = [form.formData];
      model
        .dispatch("topic/save", params)
        .then(
          (resp) => {
            console.log("update success", resp);
            CloudFun.send("info", {
              subject: form.formData.Id == 0 ? "新增成功" : "更新成功",
              content:
                form.formData.Id == 0 ? "飯店資料新增完成" : "飯店資料更新完成",
            });
            hasChanged.value = false;
            router.back();
          },
          (failure) => {
            console.log("update error", failure);
            CloudFun.send("error", { subject: "操作失敗！", content: failure });
          }
        )
        .finally();

      //   e.preventDefault();
    };

    return {
      title,
      params,
      form,
      canEdit,
      formRules,
      validate,
      classicEditor,
      simpleEditorConfig,
      save,
      uploadAction: `${process.env.VUE_APP_BACKEND_URL}/api/files`,
    };
  },
  methods: {
    back() {
      this.$router.back();
    },
  },
});
</script>

<style scoped></style>
